import React from 'react';
import { h, render } from "preact";
//import ReactDOM from 'react-dom';
//import { createRoot } from 'react-dom/client';
import './index.css';
import App from './components/App';
//import reportWebVitals from './components/reportWebVitals';
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

serviceWorkerRegistration.register();
render(<App />, document.getElementById("root"));

/*
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
);
*/

/*
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
);
*/

//reportWebVitals();
