import React from 'react';
import { memo, useEffect, useRef } from "react";
//import LinearProgress from '@mui/material/LinearProgress';

export const ScrollObserver = memo((props) => {
  const { onIntersect, isActiveObserver } = props;
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        if (entries[0].intersectionRatio >= 1) {
          observer.disconnect();
          onIntersect();
        }
      },
      {
        root: null,
        rootMargin: "1500px",
        threshold: 1.0,
      }
    );
    if(ref.current) observer.observe(ref.current);
  }, [onIntersect]);

  return (
    <React.Fragment>
      {isActiveObserver ? (
      <div ref={ref} />
      ) : null}
    </React.Fragment>
  );
});
